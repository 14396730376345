import find from 'lodash/find';
import get from 'lodash/get';
import format from 'string-template';
import Settings from '@/config/Settings';
import { useStore } from '@/store/Store';

// when we update craft 3.5
// make sure locales are installed on aws servers
// https://github.com/craftcms/cms/issues/5593
// date: postDate @formatDateTime (format: "d F Y", locale: $locale),
const FRMonth = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
];

const store = useStore();

function setUri({ lang, uri }) {
    if (!uri || typeof uri !== 'string') {
        return uri;
    }

    let tempUri = uri;
    let tempLang = lang;

    if (tempUri.startsWith(store.baseName)) {
        tempUri = tempUri.replace(store.baseName, '');
    }

    if (!tempLang) tempLang = store.lang.value;

    const current = find(Settings.locale, (l) => l.lang === tempLang);

    if (tempUri.charAt(0) !== '/') tempUri = `/${tempUri}`;

    if (tempUri.charAt(tempUri.length - 1) === '/') tempUri = tempUri.slice(0, -1);

    let finalUri = `/${tempLang}${tempUri}`;

    if (!current.prefix) finalUri = tempUri;

    return finalUri;
}

function trans(namespace, locale, objFormat) {
    const tempLocale = locale || this.lang;
    const data = this.$options.translations;

    // on cherche dans le components
    let value = get(data[tempLocale], namespace);

    // on a rien trouver, on retourn le namespace
    if (!value) value = ' ';

    // si un object est donnée, on le format
    if (objFormat) value = format(value, objFormat);

    return value;
}

function typeHandleToName(name = '') {
    const types = {
        recette: 'Recette',
        product: 'Fiches produits',
        blog: 'Blogue',
    };

    return types[name];
}

/**
 * https://stackoverflow.com/questions/475804/svg-word-wrap-show-stopper
 */
function createSVGtext(caption, charsPerLine = 20, lineHeight = 16, params = {}) {
    const ns = 'http://www.w3.org/2000/svg';
    const svgText = document.createElementNS(ns, 'text');
    const keys = Object.keys(params);
    for (let i = 0, il = keys.length; i < il; ++i) {
        const key = keys[i];
        svgText.setAttributeNS(ns, key, params[key]);
    }

    const words = caption.split(' ');
    let line = '';
    let y = parseFloat(params.y);

    for (let n = 0; n < words.length; n++) {
        const testLine = `${line + words[n]} `;
        if (testLine.length > charsPerLine) {
            //  Add a new <tspan> element
            const svgTSpanIteration = document.createElementNS(ns, 'tspan');
            svgTSpanIteration.setAttributeNS(ns, 'x', params.x);
            svgTSpanIteration.setAttributeNS(ns, 'y', y);

            const tSpanTextNodeIteration = document.createTextNode(line);
            svgTSpanIteration.appendChild(tSpanTextNodeIteration);
            svgText.appendChild(svgTSpanIteration);

            line = `${words[n]} `;
            y += lineHeight;
        } else {
            line = testLine;
        }
    }

    const svgTSpan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
    svgTSpan.setAttributeNS(ns, 'x', params.x);
    svgTSpan.setAttributeNS(ns, 'y', y);

    const tSpanTextNode = document.createTextNode(line);
    svgTSpan.appendChild(tSpanTextNode);

    svgText.appendChild(svgTSpan);

    return svgText.outerHTML;
}

const pageClasses = 'pt-16 sm:pt-[100px] xl:pt-[154px] 2xl:pt-[168px] xl:min-h-screen';

const mixin = {
    translations: {},
    computed: {
        lang() {
            return store.lang.value;
        },
        FRMonth() {
            return FRMonth;
        },
        pageClasses() {
            return pageClasses;
        },
    },
    methods: {
        setUri,
        trans,
        typeHandleToName,
        createSVGtext,
    },
};

export default mixin;
export {
    setUri, trans, typeHandleToName, createSVGtext, FRMonth,
};
