// import { ref, onMounted } from 'vue';
import { ref } from 'vue';
import Lenis from '@studio-freight/lenis';
import Tempus from '@studio-freight/tempus';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { gsap } from 'gsap';

const lenis = ref(null);

const useLenis = (options = { lerp: 0.04 }) => {
    // onMounted(() => {
    if (!lenis.value) {
        gsap.ticker.lagSmoothing(0);

        lenis.value = new Lenis(options);
        lenis.value.on('scroll', () => {
            ScrollTrigger.update();
        });
        gsap.ticker.remove(gsap.updateRoot);
        Tempus.add((time) => {
            gsap.updateRoot(time / 1000);
            lenis.value.raf(time);
        }, 0);

        window.lenis = lenis.value;
    }
    // });

    return lenis;
};

export { useLenis, lenis };
