import camelCase from 'lodash/camelCase';

window.dataLayer = window.dataLayer || [];

export class GA {
    constructor(defaultValue) {
        this.defaultValue = defaultValue;
    }

    send({
        category, action, label, camel = true,
    }) {
        if (!category && this.defaultValue) { category = this.defaultValue.category || null; }

        if (!action && this.defaultValue) { action = this.defaultValue.action || null; }

        if (camel) { label = camelCase(label); }

        const data = {
            eventCategory: category,
            eventAction: action,
            eventLabel: label,
            event: 'genericGAEvent',
        };

        window.dataLayer.push(data);
    }
}
