<script setup>
import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import {
    computed, watch, onMounted, onBeforeUnmount, ref,
} from 'vue';
import each from 'lodash/each';

gsap.registerPlugin(SplitText);

defineProps({
    type: { default: 'reveal', type: String },
    theme: { default: 'light', type: String },
    y: { default: -20, type: Number },
    x: { default: 0, type: Number },
});

const rootel = ref(null);
let el = null;
let tl = null;
let garbage = [];
// const leave = () => hide();
const show = () => gsap.set(el, { opacity: 1 });
const hide = () => gsap.set(el, { opacity: 0 });
const cleanup = () => {
    if (tl) tl.kill();

    each(garbage, (trash) => {
        trash.revert();
    });
    garbage = [];
};

const createTL = () => {
    tl = gsap.timeline({ paused: true });
    const splitChild = new SplitText(el, {
        type: 'lines',
        linesClass: 'SplitText__child !inline-block',
    });
    const splitParent = new SplitText(el, {
        type: 'lines',
        linesClass: 'SplitText__parent !inline-block relative',
    });
    tl.from(splitChild.lines, {
        duration: 1,
        y: 50,
        alpha: 0,
        ease: 'expo.out',
        stagger: {
            each: 0.075,
        },
        onComplete: cleanup,
    });
    garbage.push(splitParent, splitChild);
};

const enter = () => {
    setTimeout(() => {
        if (!tl) createTL();

        show();
        tl.play(0);
    }, 100);
};

onMounted(() => {
    el = rootel.value.children[0];
    el.classList.add('SplitText', 'whitespace-normal');
    hide();
});

onBeforeUnmount(cleanup);
</script>

<template>
    <Parallax :y="y" v-on:enter="enter">
        <div ref="rootel">
            <slot />
        </div>
    </Parallax>
</template>
