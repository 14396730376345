<script setup>
// import { gsap } from 'gsap'
import {
    computed, watch, onMounted, onUnmounted, ref, nextTick,
} from 'vue';
import { useStore } from '@/store/Store.js';

const emit = defineEmits(['enter', 'leave']);

const props = defineProps({
    y: { default: 0, type: Number },
    x: { default: 0, type: Number },
    force3D: { default: true, type: Boolean },
    type: { default: 'offset', type: String },
    trigger: { default: 0.5, type: Number },
    threshold: { type: Array, required: false, default: () => [0] },
    root: { type: HTMLElement, required: false, default: () => null },
    rootMargin: { type: String, required: false, default: () => '0px 0px 0px 0px' },
});

const store = useStore();
const rootel = ref(null);

let unwatch = () => {};
// const rafID = 0;
const scrollY = computed(() => store.windowScroll.value.y);

const stop = () => {
    unwatch();
};

const render = () => {
    if (props.y === 0 && props.x === 0) return;

    const infos = rootel.value.getBoundingClientRect();
    const height = infos.y + infos.height;
    const duration = window.innerHeight + infos.height;

    let n = 1 - (height / duration) - props.trigger;
    if (n < -props.trigger) n = -props.trigger;

    const coord = {
        x: parseInt(n * (props.x * 2), 10),
        y: parseInt(n * (props.y * 2), 10),
    };

    if (props.force3D) {
        rootel.value.style.transform = `translate3d(${coord.x}px, ${coord.y}px, 0px)`;
    } else {
        rootel.value.style.transform = `translate(${coord.x}px, ${coord.y}px)`;
    }
};

const enter = () => {
    if (rootel.value) {
        rootel.value.classList.add('is-in-viewport');
    }
    emit('enter', rootel.value);
    unwatch = watch(scrollY, render);
};

const leave = () => {
    if (rootel.value) {
        rootel.value.classList.remove('is-in-viewport');
    }
    emit('leave', rootel.value);
    stop();
};

const observer = new IntersectionObserver((entries) => {
    const entry = entries[0];
    if (!entry.isIntersecting) {
        leave();
    } else {
        enter();
    }
}, {
    threshold: props.threshold,
    root: props.root,
    rootMargin: props.rootMargin,
});

onMounted(() => {
    observer.observe(rootel.value);
    nextTick(() => {
        render();
    }, 0);
});

onUnmounted(() => {
    observer.disconnect();
    stop();
});
</script>

<template>
    <div ref="rootel" class="Parallax">
        <slot />
    </div>
</template>
