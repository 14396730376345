// Vue
import '@/main.css';
import { createApp } from 'vue';

// Vue meta
import { createMetaManager } from 'vue-meta';

// LazySizes
// import 'lazysizes';
// import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

// Focus Trap
import { FocusTrap } from 'focus-trap-vue';

// Core
import { getRouter } from '@/core/js/Route.js';

// Mixins
import TranslationMixin from '@/core/mixins/Translation';
import CommonsMixin from '@/mixins/Commons';

// Global components
import Icon from '@/components/icon/Icon.vue';
import Btn from '@/components/btn/Btn.vue';
import Cover from '@/components/cover/Cover.vue';
import Parallax from '@/components/parallax/Parallax.vue';
import SplitText from '@/components/parallax/SplitText.vue';

// Main app
import App from './App.vue';

// window.lazySizesConfig = window.lazySizesConfig || {};
// window.lazySizesConfig.init = false;

// Directives
const clickOutside = {
    beforeMount(element, binding) {
        //  check that click was outside the el and his children
        element.clickOutsideEvent = (event) => {
            // and if it did, call method provided in attribute value
            if (!(element === event.target || element.contains(event.target))) {
                binding.value(event);
            }
        };
        document.body.addEventListener('click', element.clickOutsideEvent);
    },
    unmounted(element) {
        document.body.removeEventListener('click', element.clickOutsideEvent);
    },
};

(async () => {
    try {
        // create vue
        const router = await getRouter();

        const app = createApp(App);

        app.mixin(TranslationMixin);
        app.mixin(CommonsMixin);

        app.component('Icon', Icon);
        app.component('Btn', Btn);
        app.component('Cover', Cover);
        app.component('Parallax', Parallax);
        app.component('SplitText', SplitText);
        app.component('FocusTrap', FocusTrap);

        // app.config.globalProperties.productionTip = false
        app.use(router);
        app.use(createMetaManager());
        app.directive('click-outside', clickOutside);
        router.app = app;
        app.mount('#App');
    } catch (e) {
        // eslint-disable-next-line no-console
        console.trace(e.stack);
    }
})();
