<script setup>
// Imports
import {
    computed, watch, ref, nextTick, onMounted,
} from 'vue';
import { useRoute } from 'vue-router';
import { gsap } from 'gsap';
import { getClient } from '@/core/js/GraphqlClient.js';
import { useStore } from '@/store/Store.js';
import 'focus-visible';

// Components
import useUi from '@/components/ui/Ui.js';
import useSeo from '@/components/seo/Seo.js';
// import Share from '@/components/share/Share.vue'
import FindBio from '@/components/find-bio/FindBio.vue';
import Navigation from '@/components/navigation/Navigation.vue';
import Footer from '@/components/footer/Footer.vue';
import ScrollTop from '@/components/scrolltop/ScrollTop.vue';
import PopupNewsletter from '@/components/popupnewsletter/PopupNewsletter.vue';
import CookieConsent from '@/components/cookie-consent/CookieConsent.vue';
import Search from '@/components/search/Search.vue';
import { useLenis } from '@/components/scroll/Lenis.js';

const lenis = useLenis();
const store = useStore();
const route = useRoute();
const nav = ref(null);
getClient();
useSeo();
useUi();

const checkHashScroll = () => {
    window.setTimeout(() => {
        if (window.location.hash) {
            const elToScrollTo = document.querySelector(window.location.hash);
            if (elToScrollTo) {
                lenis.value.scrollTo(elToScrollTo, {
                    duration: 1.5,
                });
            }
        }
    }, 500);
};

gsap.config({
    force3D: true,
});

const beforeEnterPage = (el) => {
    if (store.isBot.value) {
        return;
    }
    gsap.set(el, {
        alpha: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: '10',
    });
};

const enterPage = (el, done) => {
    const element = el;

    if (store.isBot.value) {
        done();
        return;
    }

    if (typeof route.params.noscroll === 'undefined') {
        lenis.value.scrollTo(0, { immediate: true });
    }

    gsap.to(el, {
        duration: 0.5,
        alpha: 1,
        onComplete: () => {
            element.style.position = '';
            element.style.width = '';
            element.style.opacity = '';
            element.style.top = '';
            element.style.zIndex = '';
        },
    });
    done();
};

const beforeLeavePage = (el) => {
    if (store.isBot.value) {
        return;
    }
    gsap.set(el, { alpha: 1 });
};

const leavePage = (el, done) => {
    if (store.isBot.value) {
        done();
        return;
    }
    gsap.to(el, {
        duration: 0.5,
        alpha: 1,
        onComplete: () => {
            done();
        },
    });
};

let defaultCssClass = 'portrait';
if (store.md.value) {
    defaultCssClass = 'tablet';
} else if (store.xl.value) {
    defaultCssClass = 'landscape';
}

document.documentElement.classList.add(defaultCssClass);

const orientation = computed(() => {
    if (store.md.value) {
        return 'tablet';
    }
    if (store.xl.value) {
        return 'landscape';
    }
    return 'portrait';
});

watch(orientation, (newVal, oldVal) => {
    if (!oldVal) {
        document.documentElement.classList.remove(defaultCssClass);
    } else {
        document.documentElement.classList.remove(oldVal);
    }
    document.documentElement.classList.add(newVal);
});

// useScroll();

const isFooterRendered = ref(false);
const isPopupRendered = ref(false);
const isPageRendered = ref(false);
const isLoading = ref(false);
const maybeAddMSDynamicsJS = () => {
    let msDynamicsFormLoader = document.querySelector('#ms_dynamics_form_loader');
    if (!msDynamicsFormLoader) {
        msDynamicsFormLoader = document.createElement('script');
        msDynamicsFormLoader.setAttribute('id', 'ms_dynamics_form_loader');
        msDynamicsFormLoader.setAttribute(
            'src',
            'https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.84.2007',
        );
        document.head.appendChild(msDynamicsFormLoader);
    }
    let msDynamicsFormTracking = document.querySelector('#ms_dynamics_form_tracking');
    if (!msDynamicsFormTracking) {
        msDynamicsFormTracking = document.createElement('script');
        msDynamicsFormTracking.setAttribute('id', 'ms_dynamics_form_tracking');
        msDynamicsFormTracking.setAttribute(
            'src',
            'https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.84.2007',
        );
        document.head.appendChild(msDynamicsFormTracking);
    }
};

const maybeRemoveMSDynamicsJS = () => {
    const msDynamicsFormLoader = document.querySelector('#ms_dynamics_form_loader');
    if (msDynamicsFormLoader) {
        document.head.removeChild(msDynamicsFormLoader);
    }
    const msDynamicsFormTracking = document.querySelector('#ms_dynamics_form_tracking');
    if (msDynamicsFormTracking) {
        document.head.removeChild(msDynamicsFormTracking);
        if (window.MsCrmMkt) {
            window.MsCrmMkt = undefined;
        }
    }
    const forms = document.querySelectorAll('[data-form-block-id] form');
    if (forms) {
        for (let i = 0, il = forms.length; i < il; ++i) {
            const form = forms[i];
            form.parentNode.removeChild(form);
        }
    }
};

watch([isPageRendered, isFooterRendered, isPopupRendered, isLoading], () => {
    if (isPopupRendered.value && isPopupRendered.value && isPageRendered.value && !isLoading.value) {
        window.setTimeout(() => {
            // Nextick doesn't work?
            if (window.location.hostname !== 'localhost') {
                maybeAddMSDynamicsJS();
            }
        }, 3000);
    }
});
const declareFooterRendered = () => {
    isFooterRendered.value = true;
};
const declarePopupRendered = () => {
    isPopupRendered.value = true;
};

let loadTimeout = 0;
const enterLoading = () => {
    // Don't show loading in the first 250ms in case we have really good connection
    window.clearTimeout(loadTimeout);
    isLoading.value = true;
    loadTimeout = window.setTimeout(() => {
        document.documentElement.classList.add('wait');
    }, 250);
    if (window.location.hostname !== 'localhost') {
        maybeRemoveMSDynamicsJS();
    }
};

const leaveLoading = () => {
    window.clearTimeout(loadTimeout);
    isPageRendered.value = true;
    isLoading.value = false;
    document.documentElement.classList.remove('wait');
    nextTick(() => {
        checkHashScroll();
    });
};

onMounted(() => {
    nextTick(() => {
        checkHashScroll();
    });
});
</script>

<template>
    <div id="AppComponent">
        <Suspense timeout="0">
            <Navigation ref="nav" />
        </Suspense>
        <Search />
        <div class="Page w-full" :class="['bg-bright-'+store.headerColor.value.mainColor]">
            <div class="App__page relative z-10 min-h-screen">
                <router-view v-slot="{ Component, route: currentRoute }">
                    <transition
                        :css="false"
                        v-on:before-enter="beforeEnterPage"
                        v-on:enter="enterPage"
                        v-on:before-leave="beforeLeavePage"
                        v-on:leave="leavePage"
                    >
                        <Suspense
                            timeout="0"
                            v-on:pending="enterLoading"
                            v-on:resolve="leaveLoading"
                        >
                            <component
                                :is="Component"
                                :key="'mainPage'+currentRoute.path"
                                :class="['bg-bright-'+store.headerColor.value.mainColor]"
                            />
                        </Suspense>
                    </transition>
                </router-view>
            </div>
            <CookieConsent v-if="!isBot" />
            <Suspense timeout="0">
                <Footer v-on:footer-rendered="declareFooterRendered" />
            </Suspense>
            <FindBio v-if="isFooterRendered && ui.find_bio && route.meta.name != 'discount'" />
            <ScrollTop v-if="isFooterRendered && (landscape || tablet)" />
            <Suspense timeout="0">
                <PopupNewsletter v-if="!isBot" v-on:popup-rendered="declarePopupRendered" />
            </Suspense>
        </div>

        <div v-if="dev" class="fixed bottom-0 right-0 z-50 flex w-12 items-center justify-center bg-light-gray text-xs text-black">
            <div class="block sm:hidden">
                xs
            </div>
            <div class="hidden sm:block md:hidden">
                sm
            </div>
            <div class="hidden md:block lg:hidden">
                md
            </div>
            <div class="hidden lg:block xl:hidden">
                lg
            </div>
            <div class="hidden xl:block 2xl:hidden">
                xl
            </div>
            <div class="hidden 2xl:block 3xl:hidden">
                2xl
            </div>
            <div class="hidden 3xl:block">
                3xl
            </div>
        </div>
    </div>
</template>
