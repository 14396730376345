<script setup>
import { ref, computed, onMounted } from 'vue';
import { useQuery } from 'villus';
// import { gsap } from 'gsap';
// import Cookies from 'js-cookie';
// import each from 'lodash/each';
// import { useRouter } from 'vue-router';
import Socials from '@/components/socials/Socials.vue';
import LogoFull from '@/components/logo-full/LogoFull.vue';
import MsDynamicsForm from '@/components/ms-dynamics-form/MsDynamicsForm.vue';
// import { ScrollScene } from '@/commons/js/ScrollScene';
import { GA } from '@/mixins/Ga';
import { useStore } from '@/store/Store.js';
import { useLenis } from '@/components/scroll/Lenis.js';

const emit = defineEmits(['footerRendered']);

const lenis = useLenis();
const store = useStore();
// const router = useRouter();
const el = ref(null);
const inner = ref(null);
const variables = computed(() => ({ lang: store.lang.value }));
// const formResult = ref({});
const { data } = await useQuery({
    variables,
    query: `query Fetch($lang: [String]) {
        navigation: navigationNodes (navHandle: "footer", site: $lang) {
            title,
            element { uri }
        },
        secondary: navigationNodes (navHandle: "footerSecondary", site: $lang) {
            title,
            element { uri }
        },
        infos: globalSet(handle: "footer") {
            ... on footer_GlobalSet {
                title: globalsFooterTitle,
                input: globalsFooterInput,
                first_name: globalsFooterFirstName,
                last_name: globalsFooterLastName,
                legal: globalsFooterLegal,
                thanks: globalsFooterThanks,
                copyright: globalsFooterCopyright,
                logo: globalsFooterLogo {
                    title,
                    url @transform(format: "webp", quality: 80, width: 500, immediately: true),
                    width,
                    height
                }
            }
        }
    }`,
});

const ga = new GA({ category: 'footerMenu', action: 'clicInterne' });
const toTop = () => lenis.value.scrollTo(0, { immediate: true });

onMounted(() => {
    emit('footerRendered', true);
});

</script>

<template>
    <div ref="el" class="Footer z-0 bg-dark-indigo text-white print:hidden xl:overflow-hidden">
        <div
            ref="inner"
            class="Footer__inner relative flex h-auto w-full flex-col items-center justify-center pb-7 pt-12 xl:pb-9 xl:pt-24"
        >
            <template v-if="data">
                <div
                    class="Footer__container flex w-full flex-col flex-wrap justify-between px-6 md:flex-row xl:px-20"
                >
                    <div class="flex w-full flex-col justify-between xl:flex-row">
                        <div class="flex flex-col justify-between lg:flex-row xl:w-1/2">
                            <div class="Footer__nav w-full md:w-2/5">
                                <div
                                    v-for="nav in data.navigation"
                                    :key="nav.title"
                                    class="Footer__link mb-4 block text-lg"
                                >
                                    <router-link
                                        :key="nav.uri"
                                        :to="setUri({ uri: nav.element.uri })"
                                        active-class="router-link-active after:scale-100"
                                        class="relative inline-block pb-2 text-2xl font-bold after:bottom-0 after:left-0 after:block after:h-[2px] after:w-full after:origin-top-left after:scale-x-0 after:bg-current after:transition after:duration-500 hover:after:scale-100"
                                        v-on:click="ga.send({ label: nav.title })"
                                        v-html="nav.title"
                                    />
                                </div>
                            </div>
                            <div class="Footer__nav w-full md:w-1/2">
                                <div
                                    v-for="nav in data.secondary"
                                    :key="nav.title"
                                    class="Footer__link mb-4 block text-lg"
                                >
                                    <router-link
                                        :key="nav.uri"
                                        :to="setUri({ uri: nav.element.uri })"
                                        active-class="router-link-active after:scale-100"
                                        class="relative inline-block pb-2 text-xl after:bottom-0 after:left-0 after:block after:h-[2px] after:w-full after:origin-top-left after:scale-x-0 after:bg-current after:transition after:duration-500 hover:after:scale-100"
                                        v-on:click="ga.send({ label: nav.title })"
                                        v-html="nav.title"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="xl:w-1/3">
                            <div class="Footer__title mb-10 mt-8 w-full text-xl font-medium md:w-88 xl:-mt-2">
                                {{ data.infos.title }}
                            </div>
                            <div class="Footer__socials-input block w-full md:w-88">
                                <MsDynamicsForm
                                    ms-id="dJdmbmOhOsFka5jYCKH_YrebPwjbOILOl7hrrKDXKIuQ"
                                    block-id="bb74259b-2943-ed11-bba3-000d3af4f2d7"
                                    website-id="JdmbmOhOsFka5jYCKH_YrebPwjbOILOl7hrrKDXKIuQ"
                                    hostname="2c24ef126db343e1811a9fd1ae798f33.svc.dynamics.com"
                                />

                                <div class="Footer__socials my-8 text-white xl:mb-0 xl:mt-18">
                                    <Socials type="footer" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="Footer__bottom mt-12 flex w-full flex-col items-end justify-between px-6 md:flex-row xl:px-20">
                    <div class="Footer__legal order-2 w-full text-xl md:order-none md:w-90">
                        <small v-html="data.infos.legal" />
                        <img
                            v-if="data.infos.logo"
                            class="mt-8 h-auto w-30 xl:w-34"
                            :width="data.infos.logo[0].width"
                            :height="data.infos.logo[0].height"
                            :src="data.infos.logo[0].url"
                            :alt="data.infos.logo[0].title"
                        >
                    </div>
                    <div class="Footer__copyright-logo order-2 mt-12 flex w-full md:order-1 md:mt-0 md:w-auto md:flex-col md:text-right">
                        <button class="Footer__logo mr-auto inline-block md:ml-auto md:mr-0" v-on:click="toTop">
                            <LogoFull class="h-auto w-14 shrink-0 xl:ml-0" />
                        </button>
                        <div class="Footer__copyright order-1 mt-5 sm:order-2">
                            <small v-html="data.infos.copyright" />
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
