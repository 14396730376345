<script setup>
import {
    computed, ref, watch, toRaw,
} from 'vue';
import { useQuery } from 'villus';
import uniq from 'lodash/uniq';
import debounce from 'lodash/debounce';
import { useStore } from '@/store/Store.js';
import MagnifyingGlass from '@/icons/magnifying-glass.svg';
import Caret from '@/icons/caret.svg';
import Card from '@/components/card/Card.vue';
import Loader from '@/components/loader/Loader.vue';
import DropdownCheckboxes from '@/components/dropdown-checkboxes/DropdownCheckboxes.vue';

const searchButton = ref(null);
const searchClose = ref(null);
const searchTerm = ref('');
const debouncedSearchTerm = ref('');
const hasMore = ref(false);
const limit = ref(6);
const offset = ref(0);
const checkboxes = ref([]);
const isFirstFetch = ref(true);
const more = () => {
    offset.value += limit.value;
};
const results = ref([]);
const selectedCategories = ref([]);
const defaultSections = ['recette', 'blog', 'product'];
const selectedSections = ref(defaultSections);
const sections = ref({
    recette: 'Recettes',
    blog: 'Articles de blogue',
    product: 'Fiches produit',
});
const debouncedSearch = debounce(() => {
    offset.value = 0;
    results.value = [];
    hasMore.value = false;
    selectedCategories.value = [];
    isFirstFetch.value = true;
    debouncedSearchTerm.value = searchTerm.value;
}, 250);
const termLimit = ref(2);
const searchShouldSkip = computed(() => searchTerm.value === '' || searchTerm.value.length <= termLimit.value);

const store = useStore();

const searchVariables = computed(() => ({
    search: debouncedSearchTerm.value,
    lang: store.lang.value,
    limit: limit.value,
    offset: offset.value,
    relatedTo: selectedCategories.value.length > 0 ? ['and', ...selectedCategories.value] : null,
    sections: selectedSections.value,
}));

watch(searchTerm, () => {
    debouncedSearch();
});

const {
    data,
    isFetching,
} = useQuery({
    variables: searchVariables,
    skip: searchShouldSkip,
    query: `
        query Search($lang: [String], $search: String, $limit: Int, $offset: Int, $relatedTo: [QueryArgument], $sections: [String]) {
            results: entries(
                section: $sections,
                site: $lang,
                orderBy: "score DESC",
                search: $search,
                limit: $limit,
                offset: $offset,
                relatedTo: $relatedTo
            ) {
                uri,
                title,
                typeHandle,
                ... on recette_recette_Entry {
                    category: recetteCategory { title },
                    cover: recetteCover { title, url @transform (format: "webp", quality: 80, width: 800, height: 426, immediately: true) },
                }
                ... on blog_blog_Entry {
                    category: blogCategory { title },
                    cover: blogCover { title, url @transform (format: "webp", quality: 80, width: 800, height: 426, immediately: true) },
                }
                ... on product_product_Entry {
                    cover: flexibleImage { title, url @transform (format: "webp", quality: 80, width: 800, height: 426, immediately: true) },
                }
            }
            categories: entries(section: "blogCategories", relatedToEntries: [{section: "blog", search: $search}]) {
                id
                title
            }
            categoriesRecettes: entries(section: "recettesCategories", relatedToEntries: [{section: "recette", search: $search}]) {
                id
                title
            }
            typesRecettes: entries(section: "recettesTypes", relatedToEntries: [{section: "recette", search: $search}]) {
                id
                title
            }
            ingredients: entries(section: "ingredients", relatedToEntries: [{section: $sections, search: $search}], mainIngredient:true) {
                id
                title
            }
            total: entryCount(section: $sections, site: $lang, orderBy: "score DESC", search: $search, relatedTo: $relatedTo)
        }
    `,
});

watch(isFetching, () => {
    if (!isFetching.value) {
        isFirstFetch.value = false;
    }
});

watch(data, () => {
    results.value.push(...data.value.results);
    hasMore.value = results.value.length != data.value.total;
});

const mapCategories = (items) => {
    const res = {};

    if (items && items.length > 0) {
        for (let i = 0, il = items.length; i < il; ++i) {
            res[items[i].id] = items[i].title;
        }
    }

    return res;
};

const mapCategoryIds = (items) => {
    const res = [];

    if (items && items.length > 0) {
        for (let i = 0, il = items.length; i < il; ++i) {
            res.push(items[i].id);
        }
    }

    return res;
};

const select = (e, selection, defaults) => {
    results.value = [];
    offset.value = 0;
    hasMore.value = false;
    const choices = toRaw(e);
    selection = selection.filter((item) => !defaults.includes(item));
    selection = uniq(selection.concat(choices));
    return selection;
};

const remove = (e, selection) => {
    results.value = [];
    offset.value = 0;
    hasMore.value = false;
    const choices = toRaw(e);
    selection = selection.filter((item) => !choices.includes(item));
    return selection;
};
</script>
<template>
    <transition
        enter-active-class="transition duration-500"
        leave-active-class="transition duration-500"
        enter-from-class="opacity-0 -translate-y-20"
        enter-to-class="opacity-100 translate-y-0"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 -translate-y-20"
        mode="out-in"
    >
        <focus-trap :active="store.searchOpened.value" :fallback-focus="() => searchClose || searchButton">
            <div
                v-show="store.searchOpened.value"
                tabindex="-1"
                class="SearchBar fixed top-0 z-50 h-full w-full overflow-auto bg-bright-blue/90 pb-12 pt-16"
                data-lenis-prevent
                v-on:keydown.esc="store.searchOpened.value = false"
            >
                <div class="mb-10 flex items-center px-6 xl:px-12">
                    <h3 class="h4">
                        Recherchez tous nos contenus bio
                    </h3>
                    <button
                        ref="searchClose"
                        tabindex="2"
                        class="z-50 ml-auto h-8 w-8 shrink-0 cursor-pointer rounded-full bg-dark-blue text-white transition duration-500 md:h-10 md:w-10 xl:h-11 xl:w-11"
                        v-on:click="store.searchOpened.value = false"
                    >
                        <svg
                            viewBox="0 0 50 50"
                            class="w-full"
                            fill-rule="evenodd"
                        >
                            <path
                                class="fill-current"
                                d="M32.6,19.5L18.8,32c-0.4,0.4-1.1,0.4-1.4,0c-0.4-0.4-0.3-1.1,0.1-1.4L31.2,18c0.4-0.4,1.1-0.4,1.4,0 C33,18.5,33,19.1,32.6,19.5z"
                            />
                            <path
                                class="fill-current"
                                d="M30.6,32.5L17.9,18.9c-0.4-0.4-0.4-1.1,0-1.4s1-0.3,1.4,0.1l12.8,13.5c0.4,0.4,0.4,1.1,0,1.4C31.7,32.9,31,32.9,30.6,32.5z"
                            />
                        </svg>
                    </button>
                </div>

                <div
                    class="SearchBar__input_wrapper mb-6 px-6 xl:px-12"
                    v-on:submit.prevent="() => {}"
                >
                    <div
                        class="SearchBar__input flex items-center rounded-lg bg-white text-dark-blue ring-2 ring-light-blue/30 transition focus-within:ring-light-blue"
                    >
                        <MagnifyingGlass class="ml-2 mr-4 h-auto w-6 shrink-0 fill-current text-light-blue" />
                        <input
                            v-model="searchTerm"
                            tabindex="1"
                            type="text"
                            class="w-full rounded-lg py-3 placeholder:text-light-blue"
                            placeholder="Recherchez..."
                        >
                        <button
                            v-if="searchTerm !== ''"
                            class="h-auto w-8 shrink-0 text-light-blue"
                            v-on:click="searchTerm = ''"
                        >
                            <svg
                                viewBox="0 0 50 50"
                                class="w-full"
                                fill-rule="evenodd"
                            >
                                <path
                                    class="fill-current"
                                    d="M32.6,19.5L18.8,32c-0.4,0.4-1.1,0.4-1.4,0c-0.4-0.4-0.3-1.1,0.1-1.4L31.2,18c0.4-0.4,1.1-0.4,1.4,0 C33,18.5,33,19.1,32.6,19.5z"
                                />
                                <path
                                    class="fill-current"
                                    d="M30.6,32.5L17.9,18.9c-0.4-0.4-0.4-1.1,0-1.4s1-0.3,1.4,0.1l12.8,13.5c0.4,0.4,0.4,1.1,0,1.4C31.7,32.9,31,32.9,30.6,32.5z"
                                />
                            </svg>
                        </button>
                    </div>
                </div>

                <div class="relative z-50 mb-6">
                    <div class="relative z-20 flex w-screen flex-wrap gap-4 px-6 pb-4 md:pb-0 xl:px-12">
                        <DropdownCheckboxes
                            v-if="searchTerm && !isFirstFetch && data"
                            ref="checkboxes"
                            :options="sections"
                            :default-value="defaultSections"
                            label="Types de contenus"
                            class="relative z-10 w-full md:w-auto"
                            v-on:input="(val) => { selectedSections = select(val, selectedSections, defaultSections) }"
                            v-on:reset="selectedSections = defaultSections"
                        />
                        <DropdownCheckboxes
                            v-if="searchTerm && !isFirstFetch && data && data.categories && data.categories.length > 0"
                            ref="checkboxes"
                            :options="mapCategories(data.categories)"
                            label="Blogue"
                            :default-value="[]"
                            class="relative z-9 w-full md:w-auto"
                            v-on:input="(val) => { selectedCategories = select(val, selectedCategories, mapCategoryIds(data.categories)) }"
                            v-on:before-reset="(val) => { selectedCategories = remove(val, selectedCategories) }"
                        />
                        <DropdownCheckboxes
                            v-if="searchTerm && !isFirstFetch && data && data.categoriesRecettes && data.categoriesRecettes.length > 0"
                            ref="checkboxes"
                            :options="mapCategories(data.categoriesRecettes)"
                            label="Recettes"
                            :default-value="[]"
                            class="relative z-8 w-full md:w-auto"
                            v-on:input="(val) => { selectedCategories = select(val, selectedCategories, mapCategoryIds(data.categoriesRecettes)) }"
                            v-on:before-reset="(val) => { selectedCategories = remove(val, selectedCategories) }"
                        />
                        <DropdownCheckboxes
                            v-if="searchTerm && !isFirstFetch && data && data.typesRecettes && data.typesRecettes.length > 0"
                            ref="checkboxes"
                            :options="mapCategories(data.typesRecettes)"
                            label="Régime"
                            :default-value="[]"
                            class="relative z-7 w-full md:w-auto"
                            v-on:input="(val) => { selectedCategories = select(val, selectedCategories, mapCategoryIds(data.typesRecettes)) }"
                            v-on:before-reset="(val) => { selectedCategories = remove(val, selectedCategories) }"
                        />
                        <DropdownCheckboxes
                            v-if="searchTerm && !isFirstFetch && data && data.ingredients && data.ingredients.length > 0"
                            ref="checkboxes"
                            :options="mapCategories(data.ingredients)"
                            label="Ingrédients"
                            :default-value="[]"
                            class="relative z-6 w-full md:w-auto"
                            v-on:input="(val) => { selectedCategories = select(val, selectedCategories, mapCategoryIds(data.ingredients)) }"
                            v-on:before-reset="(val) => { selectedCategories = remove(val, selectedCategories) }"
                        />
                    </div>
                </div>

                <Loader
                    v-if="isFetching && results.length == 0"
                    class="mx-auto"
                    bg="dark-blue"
                    line="white"
                />

                <div v-if="searchTerm.length <= termLimit" class="SearchBar__bottom px-6 xl:px-12">
                    <h4 class="h4 mb-2">
                        Essayez les termes suivants
                    </h4>
                    <div class="SearchBar__terms groupnot flex flex-wrap gap-x-4 gap-y-1">
                        <button
                            v-for="term in (ui.search_suggestions || 'Carottes, Environnement, Canneberges').split(',').map(item => item.trim())"
                            :key="term"
                            tabindex="3"
                            class="rounded-full bg-dark-blue px-3 py-1 text-sm text-white transition focus:border-none focus:outline-none groupnot-focuswithin:opacity-50"
                            v-on:click="searchTerm = $event.target.innerText"
                        >
                            {{ term }}
                        </button>
                    </div>
                </div>

                <div
                    v-else-if="results && results.length > 0"
                    class="SearchBar__results relative z-10 flex flex-wrap gap-y-4 px-6 text-dark-blue md:gap-x-[6%] lg:gap-x-[5%] xl:px-12"
                >
                    <template v-for="post in results" :key="post.uri">
                        <transition
                            enter-active-class="transition duration-500"
                            leave-active-class="transition duration-500"
                            enter-from-class="opacity-0 translate-y-4"
                            enter-to-class="opacity-100 translate-y-0"
                            leave-from-class="opacity-100 translate-y-0"
                            leave-to-class="opacity-0 translate-y-4"
                            mode="out-in"
                        >
                            <Card
                                class="w-full md:w-[47%] lg:w-[30%]"
                                :tag="true"
                                :post="post"
                                color="dark-blue"
                            />
                        </transition>
                    </template>
                </div>

                <div v-else-if="!isFetching && results.length == 0 && searchTerm.length > termLimit && searchTerm == debouncedSearchTerm" class="px-6 xl:px-12">
                    Aucun résultat ne correspond à vos termes de recherche.
                </div>

                <div v-if="hasMore && results.length > 0" class="SearchBar__results-bottom flex w-full flex-wrap justify-center px-6 xl:px-20">
                    <button
                        class="BtnPagination group mx-auto inline-flex cursor-pointer items-center rounded-xl bg-dark-blue px-4 py-2 text-sm font-medium text-white"
                        :class="{
                            'opacity-50': isFetching,
                        }"
                        :disabled="isFetching"
                        v-on:click.stop="more"
                    >
                        <span>Charger plus</span>
                        <Loader
                            v-if="isFetching"
                            size="small"
                            class="ml-2"
                            bg="transparent"
                            line="white"
                        />
                    </button>
                </div>
            </div>
        </focus-trap>
    </transition>
</template>
