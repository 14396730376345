import { useStore } from '@/store/Store';

const store = useStore();
export default {
    computed: {
        ui() { return store.ui.value; },
        portrait() {
            return store.xs.value;
        },
        tablet() {
            return store.md.value;
        },
        landscape() {
            return store.xl.value;
        },
        isBot() {
            return store.isBot.value;
        },
        xs() {
            return store.xs.value;
        },
        md() {
            return store.md.value;
        },
        xl() {
            return store.xl.value;
        },
        dev() {
            return window.location.hostname === 'localhost';
        },
    },
};
