export default {
    author: 'Lg2',
    name: 'Lg2__bioquebec',
    cms: 'craft',
    locale: [
    // first is default
        { lang: 'fr', craftID: 1, prefix: false },
    // { lang: 'en', craftID: 3, prefix: true }
    ],
};
