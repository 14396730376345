<script setup>
import {
    toRefs, onMounted, ref, nextTick,
} from 'vue';
import { gsap } from 'gsap';
import LogoFull from '@/components/logo-full/LogoFull.vue';
import NavigationSubmenu from './NavigationSubmenu.vue';
import TransitionExpand from '@/components/transition-expand/TransitionExpand.vue';
import { GA } from '@/mixins/Ga';
import Caret from '@/icons/caret.svg';
import DemiLogo from '@/icons/demi-logo.svg';

const roots = ref([]);

const props = defineProps({
    opened: {
        type: Boolean,
        default: false,
    },
    menu: {
        type: Object,
        default: () => {},
    },
});

onMounted(() => {
    nextTick(() => {
        for (let i = 0, il = roots.value.length; i < il; ++i) {
            const rootEl = roots.value[i];
            gsap.set(rootEl, { height: 0 });
        }
    });
});

const { opened: menuOpened } = toRefs(props);
const ga = new GA({ category: 'hamburgerMenuMobile', action: 'clicInterne' });

const toggleTopLevelMenu = (link) => {
    const currentLink = link;

    ga.send({ label: currentLink.title });
    currentLink.mobileOpened = !currentLink.mobileOpened;
};
</script>

<template>
    <transition
        enter-active-class="transition duration-500"
        leave-active-class="transition duration-500"
        enter-from-class="opacity-0 -translate-y-4 xl:translate-y-4"
        enter-to-class="opacity-100 translate-y-0"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 -translate-y-4 xl:translate-y-4"
        mode="out-in"
    >
        <div
            v-show="menuOpened"
            class="NavigationLightbox absolute inset-x-0 top-0 z-50 flex h-screen overflow-auto bg-bright-indigo text-white transition duration-500 xl:left-auto xl:right-8 xl:top-22 xl:h-auto xl:w-[875px] xl:overflow-visible xl:rounded-xl xl:bg-white"
            :data-lenis-prevent="xs || md"
        >
            <a :href="menu.saison[0].uri" class="NavigationMagazines group">

                <transition
                    enter-from-class="!rotate-0 !translate-x-4"
                    enter-to-class="!-rotate-20"
                    leave-from-class="!-rotate-20"
                    leave-to-class="!-rotate-20"
                >
                    <div
                        v-show="menuOpened"
                        class="NavigationLightbox__left relative hidden w-[258px] -rotate-20 scale-75 overflow-hidden rounded-xl shadow-xl transition delay-75 duration-500 group-hover:-rotate-35 xl:absolute xl:top-[9%] xl:ml-[-91px] xl:block xl:h-4/5 group-hover:xl:-translate-x-4"
                    >
                        <template v-if="menu?.saison?.[0]?.imageCover3 && menu.saison[0].imageCover3.length > 0">
                            <div
                                :key="menu.saison[0].imageCover3[0].url"
                                class="NavigationLightbox__cover absolute inset-0 opacity-100 transition duration-1000"
                            >
                                <Cover
                                    class="h-full"
                                    color="blue"
                                    :data="[menu.saison[0].imageCover3[0]]"
                                />
                            </div>
                        </template>
                    </div>
                </transition>

                <transition
                    enter-from-class="!rotate-0 !translate-x-2"
                    enter-to-class="!-rotate-12"
                    leave-from-class="!-rotate-12"
                    leave-to-class="!-rotate-12"
                >
                    <div
                        v-show="menuOpened"
                        class="NavigationLightbox__left relative hidden w-[258px] -rotate-12 scale-75 overflow-hidden rounded-xl shadow-md transition duration-500 group-hover:-rotate-20 xl:absolute xl:top-[5%] xl:ml-[-87px] xl:block xl:h-4/5 group-hover:xl:-translate-x-2"
                    >
                        <template v-if="menu?.saison?.[0]?.imageCover2 && menu.saison[0].imageCover2.length > 0">
                            <div
                                :key="menu.saison[0].imageCover2[0].url"
                                class="NavigationLightbox__cover absolute inset-0 opacity-100 transition duration-1000"
                            >
                                <Cover
                                    class="h-full"
                                    color="blue"
                                    :data="[menu.saison[0].imageCover2[0]]"
                                />
                            </div>
                        </template>
                    </div>
                </transition>

                <div
                    class="NavigationLightbox__left relative hidden w-[258px] overflow-hidden rounded-xl shadow xl:absolute xl:top-[10%] xl:ml-[-42px] xl:block xl:h-4/5"
                >
                    <template v-if="menu?.saison?.[0]?.flexibleImage && menu.saison[0].flexibleImage.length > 0">
                        <transition enter-from-class="!opacity-0" leave-active-class="!opacity-0">
                            <div
                                :key="menu.saison[0].flexibleImage[0].url"
                                class="NavigationLightbox__cover absolute inset-0 opacity-100 transition duration-1000"
                            >
                                <Cover
                                    class="h-full"
                                    color="blue"
                                    :data="[menu.saison[0].flexibleImage[0]]"
                                />
                                <div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                                    <DemiLogo />

                                </div>
                            </div>
                        </transition>
                    </template>
                </div>
            </a>

            <div
                class="NavigationLightbox__right flex flex-row flex-wrap justify-center text-light-indigo  xl:flex-col xl:flex-nowrap xl:items-start xl:text-black"
            >
                <template v-if="menu">
                    <div
                        class="NavigationLightbox__main flex w-full shrink-0 flex-wrap pt-18 md:w-full xl:hidden xl:h-95 xl:shrink xl:flex-col"
                    >
                        <div
                            v-for="link in menu.main"
                            :key="link.id"
                            class="w-full"
                        >
                            <button
                                class="group relative w-full cursor-pointer border-b border-solid border-light-blue transition duration-500 first:border-t xl:-ml-18 xl:mb-12 xl:w-126 xl:last:mb-0"
                                :class="{'bg-white':link.mobileOpened, 'border-transparent':link.mobileOpened}"
                                v-on:click.stop="toggleTopLevelMenu(link)"
                            >
                                <span class="NavigationLightbox__main-fix flex items-center justify-between p-5">
                                    <!-- <Icon
                                        class="relative mr-4 hidden w-15 text-light-green opacity-0 xl:block"
                                        color="currentcolor"
                                        type="semi-circle"
                                    /> -->
                                    <h3 class="NavigationLightbox__title text-left text-2xl font-medium md:text-4xl xl:text-5xl">
                                        <div class="text-sm font-medium leading-none" :class="[(link.mobileOpened ? 'text-light-' + link.color : 'text-light-blue')]">{{ link.suptitle }}</div>
                                        {{ link.title }}
                                    </h3>
                                    <Caret class="h-4 w-auto fill-current transition duration-500" :class="{'rotate-90':!link.mobileOpened, '-rotate-90':link.mobileOpened}" />
                                </span>
                            </button>
                            <TransitionExpand>
                                <div
                                    v-show="link.mobileOpened"
                                    ref="roots"
                                    class="sousmenu__inner relative z-10 w-full overflow-hidden"
                                >
                                    <NavigationSubmenu class="relative flex flex-col justify-between gap-6 bg-white px-3 xl:flex-row" :link="link" />
                                </div>
                            </TransitionExpand>
                        </div>
                    </div>

                    <div
                        class="NavigationLightbox__secondary flex w-full shrink-0 flex-col items-end xl:w-auto xl:shrink xl:flex-row xl:overflow-hidden xl:rounded-xl"
                    >
                        <div class="NavigationLightbox__secondary-left order-2 flex w-full flex-wrap xl:order-1 xl:w-full xl:pl-[220px]">
                            <div
                                v-for="link in menu.secondary"
                                :key="link.id"
                                class="w-1/2 border-b border-r border-light-blue px-5 py-3 xl:border-bright-blue xl:p-2.5"
                            >
                                <router-link
                                    class="can-hover:hover-line relative inline-block"
                                    :to="setUri({ uri: link.uri })"
                                >
                                    <span
                                        class="NavigationLightbox__title text-base font-medium md:text-lg"
                                        v-on:click="ga.send({ label: link.title })"
                                    >
                                        {{ link.title }}
                                    </span>
                                </router-link>
                            </div>
                            <div
                                class="order-first flex w-full flex-col items-center justify-between py-3 md:flex-row xl:order-none xl:ml-[-220px] xl:w-[200%] xl:pl-[220px] xl:pr-3"
                                :class="['bg-light-'+ menu.saison[0].color]"
                            >
                                <div class="mb-2 w-full px-5 text-left text-base font-medium leading-snug text-white md:w-auto md:max-w-xs xl:mb-0 xl:text-xs xl:leading-snug">
                                    {{ menu.saison[0].featuredContentLinkText }}
                                </div>
                                <div class="w-full px-5 md:w-auto xl:px-0">
                                    <router-link
                                        class="HomeProducts__cta w-full md:w-auto"
                                        :to="menu.saison[0].uri"
                                    >
                                        <Btn
                                            icon="arrow"
                                            class="w-full text-white md:w-auto"
                                            :class="['bg-dark-'+ menu.saison[0].color]"
                                            :text="menu.saison[0].featuredContentMainLinkText"
                                        />
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="NavigationLightbox__bottom absolute left-0 top-0 mb-4 flex w-full items-end justify-between px-6 xl:hidden"
                    >
                        <router-link :to="{ name: 'home.' + lang }">
                            <LogoFull class="mt-3 h-auto w-8" />
                        </router-link>
                        <button
                            class="z-50 ml-auto h-8 w-8 cursor-pointer rounded-full bg-dark-blue text-white transition duration-500 md:h-10 md:w-10 xl:h-11 xl:w-11"
                            v-on:click="menuOpened = false"
                        >
                            <svg
                                viewBox="0 0 50 50"
                                class="w-full"
                                fill-rule="evenodd"
                            >
                                <path
                                    class="fill-current"
                                    d="M32.6,19.5L18.8,32c-0.4,0.4-1.1,0.4-1.4,0c-0.4-0.4-0.3-1.1,0.1-1.4L31.2,18c0.4-0.4,1.1-0.4,1.4,0 C33,18.5,33,19.1,32.6,19.5z"
                                />
                                <path
                                    class="fill-current"
                                    d="M30.6,32.5L17.9,18.9c-0.4-0.4-0.4-1.1,0-1.4s1-0.3,1.4,0.1l12.8,13.5c0.4,0.4,0.4,1.1,0,1.4C31.7,32.9,31,32.9,30.6,32.5z"
                                />
                            </svg>
                        </button>
                    </div>

                    <div v-if="menu.infos" class="NavigationLightbox__credits p-6 text-center text-light-blue xl:hidden">
                        {{ menu.infos.copyright }}
                    </div>
                </template>
            </div>
        </div>
    </transition>
</template>
