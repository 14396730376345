import each from 'lodash/each';
import size from 'lodash/size';
import { createRouter, createWebHistory } from 'vue-router';
import Cookies from 'js-cookie';
import Settings from '@/config/Settings';
import { useStore } from '@/store/Store';

const store = useStore();
const router = { instance: null };

// Bug in Vite.js prevents dynamic imports to be resolved like with webpack
// const pageComponents = {
//   '404/404': () => import('../../pages/404/404.vue'),
//   'about/About': () => import('../../pages/about/About.vue'),
//   'bio/Bio': () => import('../../pages/bio/Bio.vue'),
//   'blog/Blog': () => import('../../pages/blog/Blog.vue'),
//   'blogs/Blogs': () => import('../../pages/blogs/Blogs.vue'),
//   'contact/Contact': () => import('../../pages/contact/Contact.vue'),
//   'discounts/Discounts': () => import('../../pages/discounts/Discounts.vue'),
//   'faq/Faq': () => import('../../pages/faq/Faq.vue'),
//   'grocery/Grocery': () => import('../../pages/grocery/Grocery.vue'),
//   'home/Home': () => import('../../pages/home/Home.vue'),
//   'home/HomeDiscounts': () => import('../../pages/home/HomeDiscounts.vue'),
//   'home/HomeFaq': () => import('../../pages/home/HomeFaq.vue'),
//   'location/Location': () => import('../../pages/location/Location.vue'),
//   'location/LocationFarmer': () => import('../../pages/location/LocationFarmer.vue'),
//   'location/LocationSaleSpecialist': () => import('../../pages/location/LocationSaleSpecialist.vue'),
//   'location/LocationSpecializedGrocery': () => import('../../pages/location/LocationSpecializedGrocery.vue'),
//   'location/LocationSuperStore': () => import('../../pages/location/LocationSuperStore.vue'),
//   'pages/Pages': () => import('../../pages/pages/Pages.vue'),
//   'product/Product': () => import('../../pages/product/Product.vue'),
//   'products/Products': () => import('../../pages/products/Products.vue'),
//   'recette/Recette': () => import('../../pages/recette/Recette.vue'),
//   'recettes/Recettes': () => import('../../pages/recettes/Recettes.vue')
// }
const pageComponents = import.meta.glob('../../pages/**/*.vue');

export const mappingRoutes = (routeMap, main) => {
    const map = [];
    each(routeMap, (route) => {
        const componentName = `../../pages/${route.component}.vue`;
        map.push({
            path: route.url,
            name: `${route.name}.${route.local.lang}`,
            meta: {
                name: route.name,
                lang: route.local.lang,
                id: route.id,
            },
            // component: pageComponents[route.component]
            component: pageComponents[componentName],
        });
    });

    // redirection sur la home (lang detect) + 404
    map.push({ path: '/', redirect: { name: `${main}.${Settings.locale[0].lang}` } });
    map.push({ path: '/:pathMatch(.*)*', redirect: { name: `p404.${Settings.locale[0].lang}` } });

    return map;
};

export const parseCraftRoutes = (json) => {
    const routes = [];
    let main = '';

    each(json.sections, (section) => {
        each(Settings.locale, (local) => {
            const s = size(Settings.locale) > 1 ? section[local.craftID] : section;

            if (s.template == null || s.template === '') return;

            let { url } = s;

            if (url.search('vuejs_')) {
                url = url.replace(/vuejs_/g, '');
                url = url.replace(/:_is_optional/g, '?');
                url = url.replace(/_is_optional/g, '?');
                url = url.replace(/\.one\(\)\./g, '_');
                url = url.replace(/\./g, '_');
            }

            let prefix = '';
            if (local.prefix) prefix = `/${local.lang}`;

            if (prefix && url === '/') url = '';

            // fix vuejs 3
            // if(url!=='/') {
            //   url = '/' + url
            // }

            const route = {
                id: s.handle,
                name: s.handle,
                component: s.template,
                local,
                url: prefix + url,
            };

            if (route.url === '/') main = s.handle;

            routes.push(route);
        });
    });

    // each(json.rules, (section, path) => {

    //     each(Settings.locale, (local) => {
    //         let url = path;

    //         if(!url.startsWith('/')) {
    //             url = "/" + url;
    //         }

    //         if (url.search("<vuejs_") == -1) return;

    //         url = url.replace(/<vuejs_/g, ":");
    //         url = url.replace(/:{slug}/g, "");
    //         url = url.replace(/>/g, "");

    //         let template = section;
    //         template = template.replace(/^site\//, '');
    //         let parts = template.split('/');
    //         if(parts.length != 2) return;

    //         parts[1] = parts[1].charAt(0).toUpperCase() + parts[1].slice(1);
    //         template = parts.join('/');

    //         let prefix = "";
    //         if (local.prefix) prefix = "/" + local.lang;

    //         if (prefix && url == "/") url = "";

    //         const route = {
    //             id: parts[0],
    //             name: parts[0],
    //             component: template,
    //             local: local,
    //             url: prefix + url,
    //         };

    //         if (route.url == "/") main = s.handle;

    //         routes.push(route);
    //     });
    // });

    return { routes, main };
};

export const getRouter = async () => {
    let cmsRoutes = null;

    if (Settings.cms === 'craft') {
        if (window.CRAFT_ROUTES) {
            cmsRoutes = parseCraftRoutes(window.CRAFT_ROUTES);
        } else {
            const res = await fetch('/actions/route-map/routes/get-all-route-rules?format=Vue');
            const json = await res.json();
            cmsRoutes = parseCraftRoutes(json);
        }
    }

    const theroutes = mappingRoutes(cmsRoutes.routes, cmsRoutes.main);

    router.instance = createRouter({
        history: createWebHistory(),
        routes: theroutes,
        // scrollBehavior (to, from, savedPosition) {
        //   return { x: 0, y: 0 }
        // }
    });

    router.instance.beforeEach((to, redirect, next) => {
        store.route.value.from = redirect;
        next();
    });

    router.instance.afterEach((to) => {
        store.route.value.to = to;
        if (router.instance.app.lang !== to.meta.lang) {
            store.lang.value = to.meta.lang;
        }

        let qcbioPosters = Cookies.get('qcbio_posters');
        if (qcbioPosters) {
            qcbioPosters = JSON.parse(qcbioPosters);
            const fullURL = window.location.href.split('?')[0].replace(/\/$/, '');
            // console.log('qcbioPosters', fullURL, to.fullPath, qcbioPosters);
            if (qcbioPosters.active_url === fullURL) {
                Cookies.remove('qcbio_posters');
            }
        }
    });

    return router.instance;
};

export default router;
