<script setup>
import {
    ref, computed, watch, watchEffect,
} from 'vue';
import { useQuery } from 'villus';
import Cookies from 'js-cookie';
import { useRoute } from 'vue-router';
import { useStore } from '@/store/Store.js';
import MsDynamicsForm from '@/components/ms-dynamics-form/MsDynamicsForm.vue';
// import { GA } from '@/mixins/Ga';

const emit = defineEmits(['popupRendered']);

const store = useStore();
const route = useRoute();
const variables = computed(() => ({ lang: store.lang.value }));
const closeButton = ref(null);
// const ga = new GA({ category: 'popupNewsletter', action: 'clicInterne' });

const showTimeout = ref(false);
const show = ref(true);
// const storeShow = ref(false);
const cookieHide = Cookies.get('newsletter_hide');
const cookieSubscribed = Cookies.get('newsletter_subscribed');

watchEffect(() => {
    if (cookieHide || cookieSubscribed) {
        show.value = false;
    }
    // Hide newsletter popup on profile page
    if (route.path === '/pages/valider-inscription' || route.path === '/pages/profil' || route.path === '/pages/infolettre') {
        show.value = false;
    }
    // Set the cookie if we're on the "merci" screen
    if (route.path === '/pages/merci-infolettre') {
        show.value = false;
        Cookies.set('newsletter_hide', true, { expires: 730 }); // 2 years
    }
});

const togglePopup = (val) => {
    store.showPopupNewsletter.value = val;
    show.value = val;
    Cookies.set('newsletter_hide', !val, { expires: 730 }); // 2 years
};

const isShowable = () => store.showPopupNewsletter.value || (show.value && showTimeout.value);

watch(isShowable, () => {
    if (isShowable.value) {
        document.body.classList.add('overflow-hidden', 'touch-action-none');
    } else {
        document.body.classList.remove('overflow-hidden', 'touch-action-none');
    }
});

const { data, isDone } = await useQuery({
    variables,
    query: `query Fetch($lang: [String]) {
        infos: globalSet(handle: "popupNewsletter", site: $lang) {
                ... on popupNewsletter_GlobalSet {
                title: globalsPopupNewsletterTitle,
                subtitle: globalsPopupNewsletterSubtitle,
                text: globalsPopupNewsletterText,
                input: globalsPopupNewsletterInput,
                first_name: globalsPopupNewsletterFirstName,
                last_name: globalsPopupNewsletterLastName,
                thanks: globalsPopupNewsletterThanks,
                image: globalsPopupNewsletterImage {
                    title, url @transform(format: "webp", quality: 80, width:422, height:422, immediately: true), width, height
                }
            }
        }
    }`,
});

watchEffect(() => {
    if (!isDone.value) return;
    emit('popupRendered', true);
});

window.setTimeout(() => {
    showTimeout.value = true;
}, 20000);
</script>

<template>
    <transition
        v-if="data"
        name="custom-classes"
        enter-active-class="transition duration-500"
        leave-active-class="transition duration-500"
        enter-from-class="opacity-0"
        leave-to-class="opacity-0"
    >
        <div
            v-show="isShowable()"
            tabindex="-1"
            class="PopupNewsletter fixed inset-0 z-50"
            v-on:touchstart.stop
            v-on:touchmove.stop
            v-on:wheel.stop
            v-on:scroll.stop
            v-on:keydown.esc="togglePopup(false)"
        >
            <div>
                <div
                    class="fixed inset-0 bg-black opacity-50"
                    v-on:touchend.prevent.stop="togglePopup(false)"
                    v-on:click.prevent.stop="togglePopup(false)"
                />
                <transition
                    enter-active-class="transition duration-500"
                    enter-from-class="opacity-0 -translate-y-8"
                    appear
                >
                    <div
                        class="PopupNewsletter__wrap fixed inset-x-3 top-1/2 -translate-y-1/2 overflow-hidden rounded-3xl bg-light-green xl:inset-x-48"
                    >
                        <focus-trap :active="isShowable()" :fallback-focus="() => closeButton">
                            <div tabindex="-1">
                                <button
                                    ref="closeButton"
                                    class="PopupNewsletter__close absolute right-5 top-5 h-8 w-8"
                                    v-on:click.prevent.stop="togglePopup(false)"
                                    v-on:touchend.prevent.stop="togglePopup(false)"
                                >
                                    <svg class="PopupNewsletter__close-icon fill-current text-white" viewBox="0 0 50 50">
                                        <path
                                            d="M25,50A25,25,0,1,1,50,25,25,25,0,0,1,25,50ZM25,2A23,23,0,1,0,48,25,23,23,0,0,0,25,2Z"
                                        />
                                        <path
                                            d="M32.6,19.5L18.8,32c-0.4,0.4-1.1,0.4-1.4,0c-0.4-0.4-0.3-1.1,0.1-1.4L31.2,18c0.4-0.4,1.1-0.4,1.4,0 C33,18.5,33,19.1,32.6,19.5z"
                                        />
                                        <path
                                            d="M30.6,32.5L17.9,18.9c-0.4-0.4-0.4-1.1,0-1.4s1-0.3,1.4,0.1l12.8,13.5c0.4,0.4,0.4,1.1,0,1.4C31.7,32.9,31,32.9,30.6,32.5z"
                                        />
                                    </svg>
                                </button>

                                <div
                                    ref="inner"
                                    class="PopupNewsletter__inner h-full max-h-[90vh] overflow-auto overscroll-none"
                                    v-on:wheel.stop
                                    v-on:scroll.stop
                                >
                                    <div class="PopupNewsletter__container w-full pb-16 pt-8">
                                        <h1
                                            class="PopupNewsletter__title h2 px-8 text-white em:mb-2 em:mt-6 sm:px-12 em:sm:mt-2"
                                        >
                                            {{ data.infos.title ?? '' }}
                                        </h1>
                                        <div class="PopupNewsletter__content flex w-full flex-col sm:flex-row">
                                            <div class="PopupNewsletter__left mr-4 w-full sm:w-10/24 md:mr-8">
                                                <div
                                                    class="PopupNewsletter__image-wrapper relative -mx-8 h-full min-h-[26vh] overflow-hidden rounded-4xl sm:mr-0 xl:min-h-[40vh]"
                                                >
                                                    <img
                                                        class="absolute left-0 top-0 h-full w-full object-cover"
                                                        :width="data.infos.image[0].width ?? 0"
                                                        :height="data.infos.image[0].height ?? 0"
                                                        :alt="data.infos.image[0].title ?? ''"
                                                        :src="data.infos.image[0].url ?? ''"
                                                    >
                                                </div>
                                            </div>
                                            <div class="PopupNewsletter__right w-full px-8 sm:w-12/24 sm:px-0">
                                                <h3 class="PopupNewsletter__subtitle h3 my-6">
                                                    {{ data.infos.subtitle ?? '' }}
                                                </h3>

                                                <div class="PopupNewsletter__socials-input block w-full">
                                                    <MsDynamicsForm
                                                        ms-id="dJdmbmOhOsFka5jYCKH_YrebPwjbOILOl7hrrKDXKIuQ"
                                                        block-id="58111302-c244-ed11-bba2-002248d53d53"
                                                        website-id="JdmbmOhOsFka5jYCKH_YrebPwjbOILOl7hrrKDXKIuQ"
                                                        hostname="2c24ef126db343e1811a9fd1ae798f33.svc.dynamics.com"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </focus-trap>
                    </div>
                </transition>
            </div>
        </div>
    </transition>
</template>
